import cn from "classnames";
import { SOCIAL_ICONS, SOCIAL_ICONS_BRANDED } from "#constants/socialIcons";
import type { JSX } from "solid-js";

interface Social {
  label: string;
  href: string;
}

interface SocialRowProps {
  className?: string;
  negative?: boolean;
  socials?: Social[];
  white?: boolean;
}

export function SocialRow({
  className = "",
  negative = false,
  socials = [],
  white = false,
}: SocialRowProps) {
  const ICON_SET: { [key: string]: { (props: any): JSX.Element; displayName: string } } = negative ? SOCIAL_ICONS_BRANDED : SOCIAL_ICONS;
  
  return (
    <nav class={className}>
      <ul class="flex space-x-4 lg:space-x-2">
        {socials.map(({ label, href }) => {
          const Icon = ICON_SET[label.toLowerCase()];

          if (!Icon) return null;

          return (
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="no-underline"
                href={href}
                aria-label={label}
                data-tagular={JSON.stringify({
                  event: "ElementClicked",
                  actionOutcome: "EXTERNALLINK",
                  outboundUrl: href,
                  webElement: {
                    location: "SECTION",
                    elementType: `LOGO-${label.toUpperCase()}`,
                    position: "Follow Us",
                  },
                })}
              >
                <Icon
                  className={cn(
                    negative ? "text-xl" : "text-2xl",
                    white && "text-white",
                  )}
                  white={white}
                  label={`Lonely Planet on ${label}`}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}