import {
  FacebookCircle,
  Facebook,
  InstagramCircle,
  Instagram,
  PinterestCircle,
  TiktokCircle,
  Tiktok,
  TwitterCircle,
  X,
  XCircle,
  YoutubeCircle,
} from "#icons/social";

import { WebsiteFill } from "#icons";

export const SOCIAL_ICONS = {
  website: WebsiteFill,
  facebook: FacebookCircle,
  twitter: XCircle || TwitterCircle,
  instagram: InstagramCircle,
  youtube: YoutubeCircle,
  pinterest: PinterestCircle,
  tiktok: TiktokCircle,
};

export const SOCIAL_ICONS_BRANDED = {
  website: WebsiteFill,
  facebook: Facebook,
  instagram: Instagram,
  tiktok: Tiktok,
  twitter: X,
};
