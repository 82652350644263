import { withIcon } from "#icons/_withIcon";

export const Tiktok = withIcon(
  ({ primary, white }) => {
    return (
      <path
        d="M17.0725 0H13.0278V16.3478C13.0278 18.2957 11.4722 19.8957 9.53626 19.8957C7.60034 19.8957 6.04469 18.2957 6.04469 16.3478C6.04469 14.4348 7.56577 12.8695 9.43257 12.8V8.69567C5.31872 8.7652 2 12.1391 2 16.3478C2 20.5913 5.38786 24 9.57085 24C13.7538 24 17.1416 20.5565 17.1416 16.3478V7.9652C18.6627 9.07827 20.5295 9.73913 22.5 9.77393V5.66957C19.4579 5.56522 17.0725 3.06087 17.0725 0Z"
        fill={white ? "#fff" : primary}
      />
    );
  },
  {
    viewBox: "0 0 24 24",
    primary: "#FF004F",
  },
);

export const TiktokCircle = withIcon(
  ({ primary, accent, white }) => (
    <>
      <path
        d="M16 0.25C24.6985 0.25 31.75 7.30152 31.75 16C31.75 24.6985 24.6985 31.75 16 31.75C7.30152 31.75 0.25 24.6985 0.25 16C0.25 7.30152 7.30152 0.25 16 0.25Z"
        fill={white ? "#fff" : primary}
      />
      <path
        d="M19.4511 13.7757C20.48 14.5138 21.7404 14.9481 23.1018 14.9481V12.3191C22.8441 12.3192 22.5871 12.2922 22.3351 12.2386V14.308C20.9738 14.308 19.7136 13.8737 18.6844 13.1356V18.5006C18.6844 21.1845 16.5164 23.36 13.8423 23.36C12.8445 23.36 11.9171 23.0573 11.1467 22.5381C12.026 23.4403 13.2522 24 14.6088 24C17.2831 24 19.4512 21.8245 19.4512 19.1406V13.7757H19.4511ZM20.3968 11.1234C19.871 10.5469 19.5258 9.80182 19.4511 8.97816V8.64001H18.7245C18.9074 9.68689 19.5312 10.5813 20.3968 11.1234ZM12.8381 20.4786C12.5443 20.092 12.3856 19.6191 12.3863 19.1329C12.3863 17.9054 13.3779 16.9101 14.6013 16.9101C14.8293 16.9101 15.0559 16.9451 15.2732 17.0143V14.3266C15.0192 14.2916 14.763 14.2768 14.5068 14.2822V16.3742C14.2894 16.3051 14.0626 16.2699 13.8346 16.2701C12.6112 16.2701 11.6197 17.2653 11.6197 18.4929C11.6197 19.361 12.1153 20.1125 12.8381 20.4786Z"
        fill={accent}
      />
      <path
        d="M18.6843 13.1356C19.7135 13.8736 20.9738 14.3079 22.335 14.3079V12.2386C21.5752 12.0761 20.9025 11.6776 20.3968 11.1234C19.531 10.5812 18.9073 9.68684 18.7245 8.64001H16.8161V19.1404C16.8117 20.3646 15.8218 21.3558 14.6011 21.3558C13.8817 21.3558 13.2426 21.0117 12.8379 20.4786C12.1151 20.1125 11.6195 19.3609 11.6195 18.493C11.6195 17.2655 12.611 16.2702 13.8344 16.2702C14.0688 16.2702 14.2947 16.3068 14.5066 16.3743V14.2823C11.8795 14.3368 9.7666 16.491 9.7666 19.1405C9.7666 20.4631 10.2928 21.6621 11.1467 22.5381C11.9171 23.0573 12.8445 23.36 13.8423 23.36C16.5165 23.36 18.6844 21.1844 18.6844 18.5006V13.1356H18.6843Z"
        fill={accent}
      />
      <path
        d="M22.335 12.2385V11.679C21.6498 11.68 20.9781 11.4875 20.3968 11.1233C20.9114 11.6887 21.589 12.0786 22.335 12.2385ZM18.7245 8.63999C18.707 8.53994 18.6936 8.43923 18.6843 8.33814V8H16.0493V18.5005C16.0451 19.7245 15.0553 20.7157 13.8344 20.7157C13.476 20.7157 13.1376 20.6303 12.8379 20.4786C13.2426 21.0116 13.8817 21.3557 14.6011 21.3557C15.8217 21.3557 16.8118 20.3646 16.8161 19.1405V8.63999H18.7245ZM14.5068 14.2823V13.6866C14.2866 13.6564 14.0646 13.6412 13.8424 13.6413C11.1679 13.6413 9 15.8169 9 18.5005C9 20.183 9.85204 21.6658 11.1468 22.538C10.2928 21.662 9.76667 20.463 9.76667 19.1404C9.76667 16.491 11.8795 14.3367 14.5068 14.2823Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 32 32",
    primary: "#FF004F",
    accent: "white",
  },
);
