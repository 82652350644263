import { withIcon } from "#icons/_withIcon";

export const Twitter = withIcon(
  ({ primary, white }) => {
    return (
      <>
        <g clip-path="url(#clip0_309_204)">
          <path
            d="M7.55016 21.75C16.6045 21.75 21.5583 14.2467 21.5583 7.74186C21.5583 7.53092 21.5536 7.3153 21.5442 7.10436C22.5079 6.40746 23.3395 5.54425 24 4.5553C23.1025 4.9546 22.1496 5.21538 21.1739 5.32874C22.2013 4.71291 22.9705 3.74547 23.3391 2.60577C22.3726 3.17856 21.3156 3.58261 20.2134 3.80061C19.4708 3.01156 18.489 2.48912 17.4197 2.31405C16.3504 2.13899 15.2532 2.32105 14.2977 2.8321C13.3423 3.34314 12.5818 4.15471 12.1338 5.14131C11.6859 6.12792 11.5754 7.23462 11.8195 8.2903C9.86249 8.19209 7.94794 7.6837 6.19998 6.7981C4.45203 5.91249 2.90969 4.66944 1.67297 3.14952C1.0444 4.23324 0.852057 5.51565 1.13503 6.73609C1.418 7.95654 2.15506 9.02345 3.19641 9.71999C2.41463 9.69517 1.64998 9.48468 0.965625 9.10592V9.16686C0.964925 10.3041 1.3581 11.4066 2.07831 12.2868C2.79852 13.167 3.80132 13.7706 4.91625 13.995C4.19206 14.1931 3.43198 14.222 2.69484 14.0794C3.00945 15.0574 3.62157 15.9129 4.44577 16.5263C5.26997 17.1398 6.26512 17.4806 7.29234 17.5012C5.54842 18.8711 3.39417 19.6141 1.17656 19.6106C0.783287 19.61 0.390399 19.5859 0 19.5384C2.25286 20.9837 4.87353 21.7514 7.55016 21.75Z"
            fill={white ? "#fff" : primary}
          />
        </g>
        <defs>
          <clip-path id="clip0_309_204">
            <rect width="24" height="24" fill={primary} />
          </clip-path>
        </defs>
      </>
    );
  },
  {
    viewBox: "0 0 24 24",
    primary: "#1DA1F2",
  },
);

export const TwitterCircle = withIcon(
  ({ primary, accent, white }) => (
    <>
      <path
        d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z"
        fill={white ? "#fff" : primary}
      />
      <path
        d="M24 11C23.4 11.3 22.8 11.4 22.1 11.5C22.8 11.1 23.3 10.5 23.5 9.7C22.9 10.1 22.2 10.3 21.4 10.5C20.8 9.9 19.9 9.5 19 9.5C16.9 9.5 15.3 11.5 15.8 13.5C13.1 13.4 10.7 12.1 9 10.1C8.1 11.6 8.6 13.5 10 14.5C9.5 14.5 9 14.3 8.5 14.1C8.5 15.6 9.6 17 11.1 17.4C10.6 17.5 10.1 17.6 9.6 17.5C10 18.8 11.2 19.8 12.7 19.8C11.5 20.7 9.7 21.2 8 21C9.5 21.9 11.2 22.5 13 22.5C19.1 22.5 22.5 17.4 22.3 12.7C23 12.3 23.6 11.7 24 11Z"
        fill={accent}
      />
    </>
  ),
  {
    viewBox: "0 0 32 32",
    primary: "#1DA1F2",
    accent: "white",
  },
);

export const TwitterFlat = withIcon(
  () => (
    <path d="M16.9667 2.23712C16.3303 2.51735 15.656 2.70232 14.9657 2.78608C15.6925 2.34922 16.2367 1.66411 16.4978 0.857291C15.8242 1.25042 15.0776 1.53658 14.2828 1.69596C13.7585 1.13526 13.0637 0.763381 12.3063 0.638063C11.549 0.512745 10.7714 0.641002 10.0944 1.00292C9.41742 1.36483 8.87886 1.94016 8.56237 2.63955C8.24588 3.33893 8.16918 4.12325 8.34417 4.87071C5.44708 4.734 2.88079 3.34212 1.16167 1.23979C0.849146 1.77091 0.686137 2.37668 0.689917 2.99292C0.689917 4.20417 1.30617 5.26879 2.23975 5.89425C1.68644 5.87664 1.14535 5.72704 0.661583 5.45792V5.50042C0.661269 6.30536 0.93944 7.08562 1.4489 7.70883C1.95836 8.33203 2.66773 8.7598 3.45667 8.91954C2.94546 9.05652 2.41004 9.07709 1.88983 8.97975C2.11375 9.67251 2.54834 10.2781 3.13297 10.712C3.71759 11.1459 4.42308 11.3864 5.151 11.4001C3.91817 12.3677 2.39592 12.8928 0.82875 12.8912C0.5525 12.8912 0.276958 12.8749 0 12.8437C1.59779 13.8668 3.45561 14.4098 5.35287 14.4084C11.7654 14.4084 15.2681 9.09875 15.2681 4.50237C15.2681 4.35362 15.2681 4.20487 15.2575 4.05612C15.9417 3.56374 16.532 2.95241 17 2.25129L16.9667 2.23712Z" />
  ),
  {
    viewBox: "0 0 17 15",
  },
);
